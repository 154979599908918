<template>
  <b-card-code title="Ajouter un Gouvernorat">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de gouvernorat </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Gouvernorat"
              label-for="Gouvernorat"
            >
              <validation-provider
                #default="{ errors }"
                name="Gouvernorat"
                rules="required"
              >
                <b-form-input
                  v-model="governorate.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Gouvernorat"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :class="{ 'disabled-cursor': isLoading || !isGovernorateValid }"
              :disabled="isLoading || !isGovernorateValid"
              @click.prevent="addGovernorate"
            >
              Ajouter
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      governorate: {
        name: '',
      },
      required,
      isLoading: false,
    }
  },
  computed: {
    isGovernorateValid() {
      return (
        this.governorate.name !== ''
      )
    },
  },
  methods: {
    async addGovernorate() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.isLoading = true
            await axios
              .post(
                '/api/governorates/create/',
                {
                  name: this.governorate.name,
                },
              )
            this.isLoading = false
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Gouvernorat ajouté avec succés')
            }, 1000)
            this.$router.push('/governorates/')
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000)
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champ obligatoire ! vérifier votre donnée !')
          }, 1000)
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
